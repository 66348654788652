import { call, delay, ForkEffect, put, select, StrictEffect, takeEvery, takeLatest } from "redux-saga/effects";

import { getErrorCodes } from "@utils/ErrorMessageUtils";

import { EventModel } from "@common/domain/models/Event";
import { MeetingModel } from "@common/domain/models/Meeting";
import { EventRepository } from "@common/domain/repositories/EventRepository";
import { MeetingRepository } from "@common/domain/repositories/MeetingRepository";

import { fetchProfilesByUserIds } from "@redux/profile/actions";
import { fetchProfilesByUserIdsSaga } from "@redux/profile/sagas";
import { getProfileByUserIdSelector } from "@redux/profile/selectors";

import { ActivitiesType } from "@constants/config";

import { IFluxStandardAction } from "@store/webInterfaces";
import { i18n } from "@translations/i18n";
import { eventActions, eventParticipantListFail, eventParticipantListSuccess, fetchEventDetailFail, fetchEventDetailSuccess, fetchRecommendedEventsFail, fetchRecommendedEventsSuccess, fetchUpcomingUserEventsFail, fetchUpcomingUserEventsSuccess, registerEventFail, registerEventSuccess } from "./actions";

export function* fetchEventDetailSaga(action: IFluxStandardAction<string>): Generator<StrictEffect, void, EventModel> {
    try {
        const eventDetailResponse: EventModel = yield call(EventRepository.fetchEventDetail, action.payload);
        yield put(fetchEventDetailSuccess(eventDetailResponse));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(fetchEventDetailFail(error));
    }
}

export function* fetchEventAttendeesInfo(attendees: string[], organiser?: string): any {
    const attendeeUserId = attendees && attendees.map((attendee: any) => {
      return attendee;
    });
    if (organiser) {
        yield call(fetchProfilesByUserIdsSaga, fetchProfilesByUserIds([organiser]));
    }
    if(attendeeUserId.length > 0) {
      yield call(fetchProfilesByUserIdsSaga, fetchProfilesByUserIds(attendeeUserId));
    }
}

export function* fetchRecommendedEventsSaga(action) {
    try {
        const recommendedEvents: EventModel[] = yield call(EventRepository.fetchRecommendedEvents, action.payload);
        yield put(fetchRecommendedEventsSuccess(recommendedEvents));
    } catch(e) {
        const error = getErrorCodes(e);
        yield put(fetchRecommendedEventsFail(error));
    }
}

export function* fetchUpcomingEventsSaga(action) {
    yield delay(500);
    try {
        const response = [];
        const { startDateTime, limit, userId, endDate, key, status, clientName } = action.payload;
        let upcomingEvents: EventModel[];
        if (!key) {
            upcomingEvents = yield call(EventRepository.fetchUpcomingEvents, { startDate: startDateTime, endDate, limit, userId, status, clientName });
        }
        const upcomingMeetings: MeetingModel[] = yield call(MeetingRepository.fetchEventList, action.payload);
        for (let i = 0; i < upcomingMeetings?.length; i++) {
            const attendees = upcomingMeetings[i]?.getAttendees();
            let title = upcomingMeetings[i]?.getTitle();
            if (upcomingMeetings[i]?.getTags()?.isSlotBooking && !upcomingMeetings[i]?.getTags()?.type) {
                yield call(fetchEventAttendeesInfo, attendees?.map((attendee) => attendee?.userId));
                const data = { organizerName: '', attendees: [] };
                for (let j = 0; j < attendees?.length; j++) {
                    const attendee = attendees[j];
                    const profile = yield select(getProfileByUserIdSelector, attendee.userId);
                    if (attendee?.organizer) {
                        data.organizerName = `${profile?.getFirstName()} ${profile?.getLastname()}`;
                    } else {
                        data.attendees.push(`${profile?.getFirstName()} ${profile?.getLastname()}`);
                    }
                }
                title = i18n.t('events.labelsAndTitles.MEETING_BETWEEN', { organizer: data.organizerName, attendees: data.attendees.join(', ')});
            }

            const responseObject = {
                id: upcomingMeetings[i].getId(),
                title,
                startDateTime: upcomingMeetings[i].getStart().datetime,
                endDateTime: upcomingMeetings[i].getEnd().datetime,
                meetingLink: upcomingMeetings[i].getLocation() || upcomingMeetings[i].getZoomMeetingLink(),
                type: ActivitiesType.MEETING
            }
            response.push(responseObject);
        }
        if (!key) {
            for (let i = 0; i < upcomingEvents.length; i++) {
                const responseObject = {
                    id: upcomingEvents[i].getId(),
                    title: upcomingEvents[i]?.getTitle(),
                    startDateTime: upcomingEvents[i].getStartDateTime().datetime,
                    endDateTime: upcomingEvents[i].getEndDateTime().datetime,
                    meetingLink: upcomingEvents[i].getMeetingLink(),
                    type: ActivitiesType.WEBINAR,
                    webinarStatus: upcomingEvents[i].getStatus(),
                    registrationStatus: upcomingEvents[i].getRegistrationStatus()
                }
                response.push(responseObject);
            }
        }
        
        
        // @ts-ignore
        const sortByStartDate = (a, b) => new Date(a.startDateTime) - new Date(b.startDateTime);

        yield put(fetchUpcomingUserEventsSuccess(response.sort(sortByStartDate)));
    } catch(e) {
        const error = getErrorCodes(e);
        yield put(fetchUpcomingUserEventsFail(error));
    }
}

export function* registerEventsSaga(action) {
    try {
        yield call(EventRepository.registerEvents, action.payload);
        yield put(registerEventSuccess(action.payload.eventId));
    } catch(e) {
        const error = getErrorCodes(e);
        yield put(registerEventFail(error));
    }
}

export function* eventParticipantListSaga(action) {
    try {
        const response = yield call(EventRepository.eventParticipantList, action.payload);
        yield call(fetchEventAttendeesInfo, response?.participants);
        const eventParticipantList = [];
        for (let i = 0; i < response?.participants?.length; i++) {
            const profile = yield select(getProfileByUserIdSelector, response?.participants[i]);
            eventParticipantList.push(profile);
          }
        yield put(eventParticipantListSuccess({ eventParticipantList, total: response.total, status: action.payload.status, page: action.payload.page }));
    } catch(e) {
        const error = getErrorCodes(e);
        yield put(eventParticipantListFail(error));
    }
}

export function* watchEvent(): Generator<ForkEffect> {
    yield takeLatest(eventActions.FETCH_EVENT_DETAIL, fetchEventDetailSaga);
    yield takeLatest(eventActions.FETCH_RECOMMENDED_EVENT, fetchRecommendedEventsSaga);
    yield takeLatest(eventActions.FETCH_UPCOMING_USER_EVENTS, fetchUpcomingEventsSaga);
    yield takeLatest(eventActions.REGISTER_EVENT, registerEventsSaga);
    yield takeEvery(eventActions.EVENT_PARTICIPANT_LIST, eventParticipantListSaga);
}


